@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Ubuntu', sans-serif !important;
}

:root {
    --blue: #1243f0;
    --darkblue: #0d1239;
    --white1: #fff;
    --white2: #ebeef1;
    --gray: #7b7e93;
    --black: #000;
}

body {
    min-height: 100vh;
    overflow-x: hidden;
    background-color: var(--white1);
}

.main {
    position: relative;
    width: 100%;
}

/** LOGIN PAGE **/

.main-login-page {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    height: 100vh;
}

.login-form-section {
    min-height: 500px;
    background: var(--white1);
    padding: 80px;
    box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    text-align: center;
}

.site-logo img {
    width: 50px;
}

.main-title {
    color: var(--blue);
    font-size: 3.5rem;
    font-weight: bold;
}

.main-title span {
    color: var(--darkblue);
    display: block;

}

.sub-title {
    color: var(--gray);
}

.form-control:focus {
    color: #212529;
    background-color: #f8fafc;
    border-color: var(--blue);
    outline: 0;
    box-shadow: none !important;
}

.form-control, .form-controll {
    width: 70%;
    height: 50px;
    border: 2px solid var(--white2);
    border-radius: 8px;
    margin-bottom: 20px;
    padding: .375rem .75rem;
    text-align: center;
    justify-content: center;
    align-content: center;
    align-items: center;
    display: flex;
}

.login-page-input {
    text-align: center;
    justify-content: center;
    align-content: center;
    align-items: center;
    display: flex;
}

.submit-form-button {
    padding: 10px 30px;
    background: var(--blue);
    color: var(--white1);
}

.submit-form-button:hover {
    background: #0d1239;
    color: var(--white1);
}

.img-side {
    background: var(--blue);
}

.img-side {
    width: 50%;
    content: "";
    min-height: 100%;
    background-color: var(--blue);
    background-repeat: repeat-y;
    height: 100%;
    position: absolute;
}

/** SIDEBAR **/
.navigation {
    position: fixed;
    width: 260px;
    height: 100%;
    background: var(--blue);
    border-left: 10px solid var(--blue);
    transition: 0.5s;
    overflow: hidden;
}

.navigation.active {
    width: 80px;
}

.navigation ul {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding-left: 0;
}

.navigation ul li {
    position: relative;
    width: 100%;
    list-style-type: none;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
}

.navigation ul li:hover,
.navigation ul li.hovered {
    background: var(--white1);
}

.navigation ul li:nth-child(1) {
    margin-bottom: 40px;
    pointer-events: none;
}

.navigation ul li a {
    position: relative;
    width: 100%;
    display: flex;
    text-decoration: none;
    color: var(--white1);
}

.navigation ul li:hover a,
.navigation ul li.hovered a {
    color: var(--blue);
}

.navigation ul li a .icon {
    position: relative;
    display: block;
    min-width: 60px;
    height: 60px;
    line-height: 70px;
    text-align: center;
}

.navigation ul li a .icon ion-icon {
    font-size: 1.75em;
}

.navigation ul li a .title {
    position: relative;
    display: block;
    padding: 0 10px;
    height: 60px;
    line-height: 60px;
    text-align: start;
    white-space: nowrap;
}

.navigation ul li:hover a::before,
.navigation ul li.hovered a::before {
    content: '';
    position: absolute;
    right: 0;
    top: -50px;
    width: 50px;
    height: 50px;
    background: transparent;
    border-radius: 50%;
    box-shadow: 35px 35px 0 10px var(--white1);
    pointer-events: none;
}

.navigation ul li:hover a::after,
.navigation ul li.hovered a::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: -50px;
    width: 50px;
    height: 50px;
    background: transparent;
    border-radius: 50%;
    box-shadow: 35px -35px 0 10px var(--white1);
    pointer-events: none;
}

/** MAIN ADMIN **/

.main {
    position: absolute;
    width: calc(100% - 260px);
    left: 260px;
    min-height: 100vh;
    background: var(--white1);
    transition: 0.5s;
}

.main.active {
    width: calc(100% - 80px);
    left: 80px;
}

.topbar {
    width: 100%;
    height: 60%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
}

.toggle {
    position: relative;
    top: 0;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.5em;
    cursor: pointer;
}

.search {
    position: relative;
    width: 400px;
    margin: 0 10px;
}

.search label {
    position: relative;
    width: 100%;
}

.search label input {
    width: 100%;
    height: 40px;
    border-radius: 40px;
    padding: 5px 20px;
    padding-left: 35px;
    font-size: 18px;
    outline: none;
    border: 1px solid var(--black);
}

.search label button {
    position: absolute;
    top: 3px;
    right: 10px;
    font-size: 1.7em;
    background: transparent;
    border: none;
}


.user {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
}

.user img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.cardBox {
    position: relative;
    width: 100%;
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 30px;
}

.cardBox .dashboardCard {
    position: relative;
    background: var(--white1);
    padding: 30px;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
}

.cardBox .dashboardCard .numbers {
    position: relative;
    font-weight: 500;
    font-size: 2.5em;
    color: var(--blue);
}

.cardBox .dashboardCard .cardName {
    color: var(--black);
    font-size: 1.1em;
    margin-top: 5px;
}

.cardBox .dashboardCard .iconBx {
    font-size: 3.5em;
    color: var(--gray);
}

.cardBox .dashboardCard:hover {
    background: var(--blue);
}

.cardBox .dashboardCard:hover .numbers,
.cardBox .dashboardCard:hover .cardName,
.cardBox .dashboardCard:hover .iconBx {
    color: var(--white1);
}

.details {
    position: relative;
    width: 100%;
    padding: 20px;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 30px;
    /*margin-top: 10px;*/
}

.details .recentOrders {
    position: relative;
    display: grid;
    min-height: 500px;
    background: var(--white1);
    padding: 20px;
    box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
}

.cardHeader {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

}

.cardHeader h2 {
    font-weight: 600;
    color: var(--blue);
    font-size: 1.4rem;
}

.btn {
    position: relative;
    padding: 5px 10px;
    background: var(--blue);
    text-decoration: none;
    color: var(--white1);
    border-radius: 6px;
}

.details table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

.details table thead td {
    font-weight: 600;
}

.details .recentOrders table tr {
    color: var(--black);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.details .recentOrders table tr:last-child {
    border-bottom: none;
}

.details .recentOrders table tbody tr:hover {
    background: var(--blue);
    color: var(--white1);
}

.details .recentOrders table tr td {
    padding: 10px;
}

.details .recentOrders table tr td:last-child {
    text-align: end;
}

.status.delivered {
    padding: 2px 4px;
    background: #8de02c;
    color: var(--white1);
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
}

.status.pending {
    padding: 2px 4px;
    background: #f9ca3f;
    color: var(--white1);
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
}

.status.inprogress {
    padding: 2px 4px;
    background: #1795ce;
    color: var(--white1);
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
}

.status.return {
    padding: 2px 4px;
    background: #f00;
    color: var(--white1);
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
}

.status.cancel {
    padding: 2px 4px;
    background: rgb(0, 0, 0);
    color: var(--white1);
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
}

.recentCustomers {
    position: relative;
    display: grid;
    min-height: 500px;
    padding: 20px;
    background: var(--white1);
    box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
}

.recentCustomers .imgBx {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
}

.recentCustomers .imgBx img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.recentCustomers table tr td {
    padding: 12px 10px;
}

.recentCustomers table tr td h4 {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.2em;
}

.recentCustomers table tr td h4 span {
    color: var(--gray);
    font-size: 14px;
}

.recentCustomers table tr:hover {
    background: var(--blue);
    color: var(--white1);
}

.recentCustomers table tr:hover td h4 span {
    color: var(--white1);
}

/* responsive */

@media (max-width: 991px) {
    .navigation {
        left: -300px;
    }

    .navigation.active {
        width: 300px;
        left: 0;
    }

    .main {
        width: 100%;
        left: 0;
    }

    .main.active {
        left: 300px
    }

    .cardBox {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .details {
        grid-template-columns: repeat(1, 1fr);
    }

    .recentOrders {
        overflow-x: auto;
    }

    .status.inprogress {
        white-space: nowrap;
    }
}

@media (max-width: 480px) {
    .cardBox {
        grid-template-columns: repeat(1, 1fr);
    }

    .cardHeader h2 {
        font-size: 20px;
    }

    .user {
        min-width: 40px;
    }

    .navigation {
        width: 100%;
        left: -100%;
        z-index: 1000;
    }

    .navigation.active {
        width: 100%;
        left: 0;
    }

    .toggle {
        z-index: 10001;
    }

    .main.active .toggle {
        position: fixed;
        right: 0;
        left: initial;
        color: var(--white1);
    }
}

/** ORDER **/
.order-main {
    position: relative;
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 85vh;
}

.order-form {
    position: relative;
    width: 80%;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 85vh;
    box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    margin-top: 30px;
}

.order-form form {
    width: 500px;
}

.order-form form input, .order-form form textarea {
    width: 100%;
    height: 50px;
    border: 2px solid var(--white2);
    border-radius: 8px;
    margin-bottom: 10px;
    padding: .375rem .75rem;
}

.order-form form textarea {
    min-height: 80px !important;
    max-height: 180px !important;
}

.checkbox-field {
    position: relative;
    display: inline-block;
    text-align: center;
}

.checkbox-field input {
    height: 15px !important;
}

.checkbox-fields {
    width: 100%;
    margin-bottom: 15px;
}

/** REPAIR  ORDER LIST **/
.recentOrders {
    position: relative;
    width: 100%;
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 30px;
}

.recentOrdersList {
    position: relative;
    display: grid;
    /*min-height: 500px;*/
    background: var(--white1);
    padding: 20px;
    box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
}

.recentOrdersList table tr td {
    padding: 10px;
    height: 40px;
}

.recentOrdersList table tr:last-child {
    border-bottom: none;
}

.recentOrdersList table tr {
    color: var(--black);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.recentOrdersList table thead td {
    font-weight: 600;
}

.recentOrdersList table tbody tr:hover {
    background: var(--blue);
    color: var(--white1);
}

.recentOrdersList table tbody tr:hover td a {
    color: var(--white1);
}

.recentOrdersList table tr td:last-child {
    text-align: end;
}

.technician-buttons a {
    margin-left: 8px;
    margin-right: 8px;
}

.id-column-table {
    width: 5%;
}

.device-column-table {
    width: 10%;
}

.client-column-table {
    width: 20%;
}

.description-column-table {
    width: 30%;
}

.date-column-table {
    width: 10%;
}

.pdf-column-table {
    width: 5%;
}

.status-column-table {
    width: 20%;
    text-align: center;
}

.pagination {
    margin-top: 25px;
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: var(--blue);
    border-color: var(--blue);
}

.page-link {
    color: var(--blue);
}

.status-1 {
    padding: 2px 4px;
    background: #f00;
    color: var(--white1);
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
}

.status-2 {
    padding: 2px 4px;
    background: #f9ca3f;
    color: var(--white1);
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
}

.status-3 {
    padding: 2px 4px;
    background: #1795ce;
    color: var(--white1);
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
}

.status-4 {
    padding: 2px 4px;
    background: #8de02c;
    color: var(--white1);
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
}

.status-5 {
    padding: 2px 4px;
    background: rgb(0, 0, 0);
    color: var(--white1);
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
}

.status-6 {
    padding: 2px 4px;
    background: #d42ce0;
    color: var(--white1);
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
}

/** REPAIR DETAIL ORDER **/

.recentOrdersDetailPage {
    position: relative;
    width: 100%;
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 30px;
    margin-top: 10px;
}

.recentOrdersDetail {
    position: relative;
    display: grid;
    background: var(--white1);
    padding: 20px;
    box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
}

.recentOrdersDetail table tr:last-child {
    border-bottom: none;
}

.recentOrdersDetail table tr {
    color: var(--black);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.recentOrdersDetail table tr td {
    padding: 10px;
    height: 40px;
}

.recentOrdersDetail table thead td {
    font-weight: 600;
}

.recentOrdersDescription {
    position: relative;
    width: 100%;
    padding: 20px;
    grid-gap: 30px;
}

.recentOrdersDescription .recentOrders {
    position: relative;
    width: 100%;
    padding: 20px;
    grid-gap: 30px;
    box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
}

.aditional-order-information {
    position: relative;
    width: 100%;
    padding: 20px;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    grid-gap: 30px;
}

.aditional-order-information .recentOrders {
    position: relative;
    display: grid;
    background: var(--white1);
    padding: 20px;
    box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    grid-gap: 0;
}

.aditional-order-information table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

.aditional-order-information .recentOrders table tr:last-child {
    border-bottom: none;
}

.aditional-order-information .recentOrders table tr td {
    padding: 10px;
}

.aditional-order-information table thead td {
    font-weight: 600;
}

.aditional-order-information .recentOrders table tr {
    color: var(--black);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.aditional-order-information .recentCustomers {
    background: transparent;
    box-shadow: none;
    padding: 0;
}

.aditional-order-information .recentOrders .cardHeaderDescription, .aditional-order-information .recentOrders .cardHeaderNotices {
    margin-bottom: 10px;
}

.notices-box span {
    float: right;
}

.cardHeaderNotices {
    margin-top: 30px;
}

.cardHeaderStatus, .cardHeaderGuarantee, .cardHeaderFinance, .cardHeaderAddNotices {
    margin-bottom: 16px;
}

.notices-box textarea {
    resize: vertical;
    margin-bottom: 10px;
    border: 1px solid #dbdbdb !important;
    padding: 5px;
    width: 80%;
    display: block;
}

.finance-box form {
    margin-top: 10px;
}

.finance-box input {
    margin-bottom: 10px;
    border: 1px solid #dbdbdb !important;
    padding: 5px;
    width: 50%;
    margin-right: 10px;
}

select#status {
    margin-bottom: 20px;
}

input#finance, input#cost {
    display: block;
    position: relative;
    width: 50%;
    margin-bottom: 20px;
    margin-top: 10px;
}

select#guarantee {
    margin-bottom: 20px;
}

.status-box form button {
    display: block;
    margin-top: 10px;
}


/** MEMBER  DASHBOARD **/

.cardBox.member {
    grid-template-columns: repeat(3, 1fr);
}

.details.member {
    grid-template-columns: 1fr;
}

.finance-detail {
    margin-bottom: 20px;
}

.recentOrders.privacy h3 {
    font-weight: 600;
    color: var(--blue);
}

.details.member .recentOrders {
    min-height: 100px;
}

@media (max-width: 768px) {
    .login-form-section {
        padding: 20px;
    }

    .order-form {
        width: 100%;
        padding: 5px;
    }

}


.app-version {
    position: absolute;
    bottom: 5px;
    left: 10px;
    font-size: .85rem;
    color: #ffffff;
}

.app-version a {
    color: #ffffff; /* Kolor linku */
    text-decoration: underline;
}
.app-version p {
    margin-bottom: 0;
}

.app-version a:hover {
    text-decoration: underline;
}